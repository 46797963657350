import React, { useEffect, useRef, useState } from 'react'
import styles from '../styles/player.module.css';
// import { DefaultPlayer as Video, getPercentagePlayed } from 'react-html5video';
// import 'react-html5video/dist/styles.css';
import "video-react/dist/video-react.css";
import axios from 'axios';
import { Player, ControlBar, PlayToggle, VolumeMenuButton, BigPlayButton, DurationDisplay, TimeDivider, CurrentTimeDisplay, Shortcut } from 'video-react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const PlayerPage = () => {
    let vidRef = useRef(null);
    const [playerState, setPlayerState] = useState(null)
    const [duration, setDuration] = useState(null)
    const [ready, setReady] = useState(false)
    const [intervalPositions, setIntervalPositions] = useState([])
    const [countdownTimer, setCountdownTimer] = useState(30)
    const [timestamp, setTimestamp] = useState(null)
    const [timer, setTimer] = useState(0)
    const [stillWatching, setStillWatching] = useState(false)
    const [userCompleted, setUserCompleted] = useState(false)
    const [countdownIntervalObject, setCountdownIntervalObject] = useState(null)
    const [videoData, setVideoData] = useState(false)
    const [alert, setAlert] = useState(false)
    const [userInactive, setUserInactive] = useState(false)
    
    const [searchParams] = useSearchParams();

    const id = searchParams.get('id');
    const name = searchParams.get('name');
    const email = searchParams.get('email');

    const [watchPosition, setWatchPosition] = useState(() => {
        // getting stored value
        if(id){
            const saved = localStorage.getItem(id);
            return saved || 0;
        }
        
        return 0;
    })

    let navigate = useNavigate();
   
    useEffect(() => {
        window.oncontextmenu = function (e) {
            e.preventDefault();
        };

        const saved = JSON.parse(localStorage.getItem(`id_${id}`));
        setVideoData(saved)

        if(!saved) {
            navigate("/")
        }
    }, [])
    
    useEffect(() => {
        if(videoData) {
            vidRef.subscribeToStateChange((state) => setPlayerState(state))
        }
    }, [videoData])

    

    useEffect(() => {
        if(playerState) {
            if(!duration) {
                setDuration(playerState.duration)
            }
            if(!ready) {
                setReady(playerState.readyState === 4 ? true : false)
            }
            setTimestamp(Math.floor(playerState.currentTime))
        }
    }, [playerState])
    
    useEffect(() => {
        if(playerState){
            if(ready && duration > 1) {
                const randomVariable = Math.floor(Math.random() * 100) + 60
                const interval = Math.floor((duration-(duration*0.1))/3) - randomVariable
                setIntervalPositions([interval, interval*2, interval*3])
                if(watchPosition > 0){
                    changeCurrentTime(watchPosition)
                }
            }
        }
    }, [ready])

   useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer + 1)
        }, 1 * 1000); // x * 1000 milsec
        return () => clearInterval(interval)
   },[])

    useEffect(() => {
        if (timer !== 0 || timer != null){
            checkEnded()
        }
    }, [timer])

    useEffect(() => {
        if(countdownTimer === 0) {
            setUserInactive(true)
            resetDuration()
        }
        
    }, [countdownTimer])

    useEffect(() => {
        if(timestamp !== undefined && !(timestamp <= watchPosition)) {
            setWatchDuration()
        }
        checkIfWatching()
    }, [timestamp])
    
    const setWatchDuration = () => {
        localStorage.setItem(id,timestamp);
    }

    const checkIfWatching = () => {
        if(intervalPositions.includes(timestamp)){
            startCountdown()
        }
    }

    const confirmIsWatching = () => {
        clearInterval(countdownIntervalObject);
        setCountdownIntervalObject(null)
        setStillWatching(false)
        setCountdownTimer(30)
        vidRef.play()
        axios.post(`https://hook.us1.make.com/74i3emq1kqmnrozpvz4n3e3gg0gpeg59`, { name, email, id, timestamp })
    }

    const pause = () => {
        vidRef.pause();
    }

    const resetDuration = () => {
        localStorage.setItem(id, 0);
        changeCurrentTime(0)
        setTimestamp(0)
        setWatchPosition(0)
        clearInterval(countdownIntervalObject);
        setCountdownIntervalObject(null)
        setStillWatching(false)
        setCountdownTimer(30)
        pause()
    }

    const goBack = () => {
        if((timestamp - 10) >= 0){
            localStorage.setItem(id,(timestamp - 10));
        }else {
            localStorage.setItem(id,0);
        }
        changeCurrentTime(timestamp - 10)
        setTimestamp(timestamp => timestamp - 10)
        setTimestamp(watchPosition => watchPosition - 10)
    }

    const fullscreen = () => {
        vidRef.toggleFullscreen()
    }

    const checkEnded = () => {
        if(playerState && timestamp === Math.floor(duration)){
            const ended = playerState.ended
            if(ended) {
                const user = {
                    name: name,
                    email: email,
                    cle: id
                };
                setUserCompleted(true)
                resetDuration()
                axios.post(`https://hook.us1.make.com/eucshs92pgauajukc7anortfqodwhmju`, { user })
                navigate("/complete", { 
                    state: {
                        completed: true
                    }
                })
            }
        }
    }

    const changeCurrentTime = (s) => {
        vidRef.seek(s);
    }

    const startCountdown = () => {
        if(countdownTimer < 30){
            return
        }

        if(playerState.isFullscreen){
            vidRef.toggleFullscreen()
        }

        setStillWatching(true)

        pause()

        const c = setInterval(() => {
            setCountdownTimer(countdownTimer => countdownTimer - 1)
        }, 1 * 1000); // x * 1000 milsec

        setCountdownIntervalObject(c)
    }

    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <a target="_self" href="https://www.ijethics.org" rel="noreferrer">
                    <img style={{width:275}}src={require('../logo2.jpeg')} alt="logo" />
                </a>
            </div>
            <div>
            <Typography variant="h2" component="div" className={styles.videoName} gutterBottom>
                {videoData ? videoData.name : null}
            </Typography>
            </div>

            {stillWatching ? (
                <div className={styles.stillWatching}>
                    <WarningAmberIcon className={styles.marginRight}/>
                    <span className={styles.warningText}>Are you still watching?</span>
                    <span style={{fontWeight: "bold", fontSize: 38, marginRight: "25px"}}>{countdownTimer}</span>
                    <Button className={styles.marginRight} onClick={() => confirmIsWatching()} variant="contained">
                        Yes
                    </Button>
                </div>
            ) : null}

            {videoData ? (
                <div className={styles.player}>
                    <Player
                        ref={player => {
                            vidRef = player;
                        }}
                        preload="metadata"
                        src={videoData ? videoData.url : null}
                        fluid={false}
                        playsInline={true}
                        className={styles.reactVideo}
                    >
                        <BigPlayButton position="center" />
                        <ControlBar autoHide={false} disableDefaultControls={true}>
                            <PlayToggle />
                            <VolumeMenuButton vertical />
                            <CurrentTimeDisplay />
                            <TimeDivider />
                            <DurationDisplay />
                        </ControlBar>
                        <Shortcut
                                shortcuts={
                                    [
                                        {
                                            keyCode: 37, // arrowLeft
                                            handle: () => {return} // a function that does nothing
                                        },
                                        {
                                            keyCode: 39, // arrowRight
                                            handle: () => {return} // a function that does nothing
                                        },
                                        {
                                            keyCode: 74, // j
                                            handle: () => {return} // a function that does nothing
                                        },
                                        {
                                            keyCode: 76, // l
                                            handle: () => {return} // a function that does nothing
                                        }
                                    ]
                                } 
                            />
                    </Player>
                </div>
            ):(
                <Skeleton variant="rectangular" width={210} height={118} />
            )}

            <Typography variant="h7" component="div" className={styles.contactUs} gutterBottom>
                If you are experiencing technical difficulties, please call 215-782-8878 or email us at IJE@IJEthics.org
            </Typography>

            <div className={styles.controls}>
                <Button style={{marginRight: '15px'}} onClick={() => fullscreen()} variant="contained">
                    Fullscreen
                </Button>
                <Button onClick={() => goBack()} variant="contained">
                    Rewind 10 seconds
                </Button>
            </div>
            <div className={styles.controls}>
                <Button className={styles.marginRight} onClick={() => setAlert(true)} variant="outlined" color="error">
                    Restart
                </Button>
            </div>

            <RestartAlert open={alert} close={setAlert} restart={resetDuration} />
            <TimeExpiredAlert open={userInactive} close={setUserInactive}/>
        </div>
    )
}

export default PlayerPage;


const RestartAlert = (props) => {

    const restart = () => {
        props.restart()
        props.close(false)
    }

    const handleClose = () => {
        props.close(false)
    };
  
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to restart?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              If so, your progress will be lost and you will 
              need to rewatch the entire lecture from the beginning!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={restart}>Restart</Button>
            <Button onClick={handleClose} autoFocus>
              Continue Watching
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

const TimeExpiredAlert = (props) => {

    const handleClose = () => {
        props.close(false)
    };
  
    return (
      <div>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Oops!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sadly, you failed to verify that you are currently watching. Therefore,
              the video has restarted and you must watch again from the start. If this 
              was done in error, please reach out to ije@ijethics.org and we will 
              advise.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Continue</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}