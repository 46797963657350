import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import styles from '../styles/home.module.css'
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Divider } from '@mui/material';

const Home = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [searchParams] = useSearchParams();
    const [videoId, setVideoId] = useState(null)
    const [detailsAdded, setDetailsAdded] = useState(false)
    const [videoData, setVideoData] = useState(null)
    const [error, setError] = useState(null)

    let navigate = useNavigate();

    useEffect(() => {
        const id = searchParams.get('id')
        const firstName = searchParams.get('first')
        const lastName = searchParams.get('last')
        const email = searchParams.get('email')

        if(firstName && lastName) {
            setName(firstName+" "+lastName)
        }

        if(email) {
            setEmail(email)
        }

        if(!id) {
            window.location.replace("https://www.ijethics.org");
        }

        setVideoId(id)
    }, [searchParams])

    const getVideoData = async (id) => {
        axios.post(`https://hook.us1.make.com/hdr97popw4re24lddsd5i1jwifmsfwu8`, { params: { id: id }})
        .then(res => {
                localStorage.setItem(`id_${id}`, JSON.stringify(res.data));
                setVideoData(res.data)
            }
        )
    }

    useEffect(() => {
        if(videoId) {
            getVideoData(videoId)
        }
    }, [videoId])
    
    const validateEmail = (email) => {

        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if ( re.test(email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            return true
        }
        else {
            return false
        }
    }

    const startVideo = () => {
        if(name === ""){
            return
        }
        if(email === "") {
            return
        }
        if(!videoData) {
            return
        }

        const user = {
            name: name,
            email: email,
            cle: videoId
          };

        axios.post(`https://hook.us1.make.com/3zrvnvafg3josvqwjo1k8phth24hmeoy`, { user })
        navigate(`/video?id=${videoId}&name=${name}&email=${email}`);
    }

    const handleSubmit = () => {
        if(name === ""){
            setError("Invalid name")
            return
        }
        if(email === "") {
            setError("Invalid email")
            return
        }
        if(!videoData) {
            return
        }

        if(!validateEmail(email)) {
            setError("Invalid email")
            return
        }

        setDetailsAdded(true)
    }
    return (
        videoData ? (
            <div className={styles.container}>
                {detailsAdded ? null : (
                    <>
                        <img src={require('../logo.png')} alt="logo" />
                        <Typography variant="h1" component="div" className={styles.ijeHeader} gutterBottom>
                            Institute for Jewish Ethics
                        </Typography>
                        <hr style={{borderTop: '1px solid #bbb', width: '250px'}} />
                    </>
                
                )}
                <div className={styles.videoName} gutterBottom>
                    {videoData.name}
                </div>
                {detailsAdded ? null : (
                    <div className={styles.videoDesc}>
                        {videoData.description}
                    </div>
                )}
                {
                    detailsAdded ? (
                        <Instructions startVideo={startVideo} videoData={videoData}/>
                    ):(
                        videoData.url ? (
                            <Box
                                component="form"
                                sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                className={styles.form}
                            >
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="name"
                                    onChange={(e)=>{
                                            setName(e.target.value)
                                            setError(null)
                                        }
                                    }
                                    value={name}
                                />
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="email"
                                    onChange={(e)=> {
                                            setEmail(e.target.value)
                                            setError(null)
                                        }
                                    }
                                    value={email}
                                />
                                <div className={styles.error}>{error ? error : null}</div>
                                <Button onClick={() => handleSubmit()} variant="contained">
                                    Next
                                </Button>
            
                                
                            </Box>
                        ):(
                            <ComingSoon />
                        )
                    )
                }
            </div>
        ):(
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                <CircularProgress />
            </div>
        )
    );
}

export default Home;


const Instructions = (props) => {
    return (
        <Box 
            sx={{ minWidth: 275, maxWidth: 750, margin: '0 auto' }}
            display="flex"
            flexDirection="column"
            bgcolor="grey"
            alignItems="center"
        >
            <div className={styles.instructionsTitle} component="div">
                Please read these instructions for how to recieve your CLE credit:
            </div>
            <Typography className={styles.instructions} marginBottom="25px" variant="h7" component="div">
                1. Once you begin the video, you will need to watch it till the very end in order to receive credit.<br/><br/>
                2. You can pause the video at any point for any duration of time, as well as rewind, but you cannot fast forward.<br/><br/>
                3. If you click the "restart" button, you will be forced to watch from the beginning.<br/><br/>
                4. Periodically, the video will pause and a notification and button will appear above the video window to ensure you are watching. You must click the button  within 30 seconds, to confirm you are watching, otherwise the video will automatically restart from the beginning.<br/><br/>
                5. At the end of the video, a form will appear asking for your feedback and CLE information. After you submit the completed form, you will receive an email confirmation that you have completed the course and earned CLE credit.<br/><br/>
                6. If you are a PA attorney, the credit will be applied to your profile within three business days and a certificate of attendance will be emailed to you.<br/><br/>
                7. For all other states, you will receive a certificate of attendance within three business days to use for your own self-reporting.<br/><br/>
            </Typography>

            <div className={styles.instructionsTitle} component="div">
                Thank You, and Enjoy!
            </div>

            {props.videoData.handout ? (<div className={styles.handout}><a href={props.videoData.handout} download target="_blank" rel="noreferrer">CLICK HERE</a> to download the webinar written handout</div>) : null}
            
            <Button onClick={() => props.startVideo()} variant="contained">
                Start
            </Button>            
      </Box>
    );
  }


const ComingSoon = () => {
    return (
        <div>
            <div style={{textAlign: 'center', fontWeight: 'bold', fontSize: '35px', marginBottom: '15px'}}>
                Coming Soon!
            </div>
            <Typography className={styles.videoDesc}variant="h7" component="div" gutterBottom>
                This video is not yet available. Please check back later.
            </Typography>
            <Typography className={styles.videoDesc}variant="h6" component="div" gutterBottom>
                Recordings are typically available within 24 hours of the live event.
            </Typography>
        </div>
    );
}