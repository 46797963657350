import './App.css';
import PlayerPage from './pages/Player';
import Home from './pages/Home'
import Finished from './pages/Finished'
import { Routes, Route } from "react-router-dom";
import YoutubeEmbed from './pages/Youtube';

function App() {

  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="video" element={<PlayerPage />} />
        <Route path="complete" element={<Finished />} />
        <Route path="rabbicoleman" element={<YoutubeEmbed />} />
    </Routes>
  );
}

export default App;


