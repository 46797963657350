import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from '../styles/youtube.module.css'
import { useSearchParams, Link } from 'react-router-dom';

const YoutubeEmbed = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')

    useEffect(() => {
        if(!id) {
            window.location.replace("https://www.ijethics.org");
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <a target="_self" href="https://www.ijethics.org" rel="noreferrer">
                    <img style={{width:275}}src={require('../logo2.jpeg')} alt="logo" />
                </a>
            </div>
            <div className={styles.youtube}>
                <div className={styles.videoResponsive}>
                    <iframe
                        src={`https://www.youtube.com/embed/${id}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            </div>
            


        </div>
        
    )
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;