import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import styles from '../styles/finished.module.css'
import { useLocation } from 'react-router-dom'

const Finished = () => {

    let location = useLocation();

    useEffect(() => {
        if(!(location.state && location.state.completed)){
            window.location.replace("https://www.ijethics.org");
        }
    }, [])

    return (
        <div className={styles.container}>
            <Typography variant="h3" style={{textAlign: "center"}}component="div" gutterBottom>
                Congratulations, you have completed your CLE Webinar!
            </Typography>
            <Typography variant="h6" style={{textAlign: "center", maxWidth: "900px"}}component="div" gutterBottom>
                In order to recieve credit, please fill in the evaluation form below, click SUBMIT, and 
                you will recieve an email within 1-2 days with your relevant certificates of attendance. If you are 
                a PA attorney, credit will be added to your PACLE profile on your behalf. For all other states,  
                please use your certificate of attendance for self-reporting.
            </Typography>
            <iframe src="https://www.cognitoforms.com/f/Z0a78MX6ZE2HdCH_uK-EYA?id=36" id={1} title={"form"} style={{position:"relative", width:"1px", minWidth:"100%", "*width":"100%"}} seamless="seamless" height="3000px" width="100%"></iframe>
        </div>
    )
}

export default Finished;